.vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: #556ee6;
  --vjs-theme-fantasy--secondary: #fff;
}

.vjs-theme-fantasy .vjs-big-play-button {
  color: #556ee6;
}
.vjs-theme-fantasy .vjs-big-play-button:hover {
  color: #fff;
}
